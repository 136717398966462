<template>
  <Layout>
    <PageHeader title="Create Employee" />
    <div v-if="loading" class="d-flex justify-content-center mb-3">
      <b-spinner
        style="width: 3rem; height: 3rem;"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <div class="row p-4 flex justify-center">
      <div class="col-md-10 p-4 bg-light">
        <div class="form-group">
          <label for="firstName">First Name</label>
          <input
            type="text"
            v-model="formData.firstname"
            class="form-control"
            placeholder="enter first name"
          />
        </div>
        <div class="form-group">
          <label for="firstName">Last Name</label>
          <input
            type="text"
            v-model="formData.lastname"
            class="form-control"
            placeholder="enter last name"
          />
        </div>

        <div class="form-group">
          <label for="firstName">Email</label>
          <input
            type="email"
            v-model="formData.email"
            class="form-control"
            placeholder="enter email"
          />
        </div>

        <div class="form-group">
          <label for="firstName"> Phone Number</label>
          <input
            type="button"
            data-toggle="modal"
            :value="getphoneNumber"
            data-target="#businesPhonModalCenter"
            class="form-control text-left"
          />
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="businesPhonModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="businesPhonModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content pb-5">
              <div class="modal-header">
                <h5 class="modal-title" id="businesPhonModalLongTitle">
                  Verify Phone Number
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body space-y-5">
                <div v-if="show_confirm == 1">
                  <div class="row flex justify-content-center">
                    <div class="col-md-10">
                      <div class="form-group">
                        <label for="countries">Select country code</label>
                        <select
                          v-model="selected_code"
                          class="form-control"
                          name="countries"
                        >
                          <option
                            v-for="country in countries"
                            :key="country.index"
                            :value="country.code"
                          >
                            {{ country.code }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <input
                          type="number"
                          v-model="userphonenumber"
                          placeholder="enter phone number"
                          class="form-control"
                        />
                      </div>
                      <div
                        v-if="loadingAction"
                        class="d-flex justify-content-center mb-3"
                      >
                        <b-spinner label="Loading..."></b-spinner>
                      </div>
                      <button
                        v-else
                        @click.prevent="sendCode"
                        class="btn btn-block btn-primary"
                      >
                        send code
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="show_confirm == 2">
                  <div class="row flex justify-content-center">
                    <div class="col-md-10">
                      <div class="form-group">
                        <input
                          type="text"
                          placeholder="xxxxxx"
                          class="form-control"
                          v-model="confirm_otp"
                        />
                      </div>
                      <div
                        v-if="loadingAction"
                        class="d-flex justify-content-center mb-3"
                      >
                        <b-spinner label="Loading..."></b-spinner>
                      </div>
                      <button
                        v-else
                        @click.prevent="verifyCode"
                        class="btn btn-block btn-primary"
                      >
                        verify
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="custom-control pt-2 custom-checkbox">
          <input
            type="checkbox"
            v-model="formData.isactive"
            class="custom-control-input"
            id="customCheck2"
          />
          <label class="custom-control-label" for="customCheck2"
            >Is Active</label
          >
        </div>

        <button @click="createEmp" class="btn btn-success float-right my-2">
          Create User
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import $ from "jquery";
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      loading: false,
      loadingAction: false,
      formData: {
        firstname: "",
        lastname: "",
        email: "",
        PhoneNumber: "",
        isactive: false,
        empguid: null
      },
      countries: [
        {
          code: "+233",
          name: "Ghana"
        },
        {
          code: "+234",
          name: "Nigeria"
        }
      ],
      otp: null,
      selected_code: null,
      show_confirm: 1,
      userphonenumber: "",
      newPhone: null,
      confirm_otp: null,
      PhoneNum:""
    };
  },
  methods: {
    sendCode() {
      let user_phone = this.userphonenumber;
      if (user_phone.charAt(0) == 0 || user_phone.charAt(0) == 1) {
        var userPhone = user_phone.substring(1);
      }else{
        userPhone = user_phone
      }
      this.PhoneNum = `${this.selected_code}${userPhone}`;
      const data = {
        phonenumber: `${this.selected_code}${userPhone}`,
        code: this.selected_code
      };

      this.loadingAction = true;
      this.$store
        .dispatch("manageusers/VerifyPhone", data)
        .then((response) => {
          if (response.status == 200) {
            this.show_confirm = 2;
            this.loadingAction = false;
            this.otp = response.data;
          }
          // console.log(response.data);
        })
        .catch((err) => {
          this.loadingAction = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    },
    verifyCode() {
      this.loadingAction = true;
      this.$store
        .dispatch("healthhub/verifyOTP", this.confirm_otp)
        .then((response) => {
          if (response.status == 200) {
            // localStorage.setItem("adminguid", response.data);
            this.formData.PhoneNumber = this.PhoneNum
            this.formData.empguid = response.data;
            $("#businesPhonModalCenter").modal("hide");
            this.show_confirm = 1;
            this.loadingAction = false;
            this.$toasted.success("phone number confirmed successfully", {
              duration: 5000
            });
          }
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    },

    createEmp() {
      this.loading = true;
      this.$store
        .dispatch("manageusers/CreateEmp", this.formData)
        .then((response) => {
          if (response.status == 200) {
            this.loading = false;
            this.$swal({
              icon: "success",
              title: "success",
              text:
                "Employee created successfully"
            });
           

            this.$router.push("/manage-employees");
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    }
  },
  computed: {
    getphoneNumber() {
      let phone = this.formData.PhoneNumber || "phone number";
      return phone;
    }
  },
  mounted() {}
};
</script>

<style scoped>
.iconify {
  color: red;
}
</style>
